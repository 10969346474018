import Vue from "vue";
import App from "./App.vue";
// VUEX - https://vuex.vuejs.org/
import store from "./store";
// VUE-ROUTER - https://router.vuejs.org/
import router from "./router";
// PLUGINS
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/vue-i18n";
import "./plugins/vue-google-maps";
import "./plugins/vue-shortkey";
import "./plugins/vue-head";
import "./plugins/vue-gtag";
import "./plugins/apexcharts";
import "./plugins/echarts";
import "./plugins/animate";
import "./plugins/clipboard";
import "./plugins/moment";
// FILTERS
import "./filters/capitalize";
import "./filters/lowercase";
import "./filters/uppercase";
import "./filters/formatCurrency";
import "./filters/formatDate";
import "./filters/formatDateDistance";
// STYLES
// Main Theme SCSS
import "./assets/scss/theme.scss";
// Animation library - https://animate.style/
import "animate.css/animate.min.css";
// Set this to false to prevent the production tip on Vue startup.
Vue.config.productionTip = false;
/*
|---------------------------------------------------------------------
| Main Vue Instance
|---------------------------------------------------------------------
|
| Render the vue application on the <div id="app"></div> in index.html
|
| https://vuejs.org/v2/guide/instance.html
|
*/
export default new Vue({
    // @ts-ignore
    i18n: i18n,
    vuetify: vuetify,
    router: router,
    store: store,
    render: function (h) { return h(App); }
}).$mount("#app");
