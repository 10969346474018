export default [
  {
    path: "/projects",
    name: "projects-list",
    component: () =>
      import(/* webpackChunkName: "users-list" */ "@/pages/projects/ProjectsPage.vue")
  },
  {
    path: "/projects/:uuid",
    name: "projects-edit",
    component: () =>
      import(
        /* webpackChunkName: "users-edit" */ "@/pages/projects/EditProjectPage.vue"
      )
  }
];
