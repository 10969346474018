export default [
  {
    path: "/customers",
    name: "customers-list",
    component: () =>
      import(/* webpackChunkName: "users-list" */ "@/pages/customers/CustomersPage.vue")
  },
  {
    path: "/customers/:uuid",
    name: "customers-edit",
    component: () =>
      import(
        /* webpackChunkName: "users-edit" */ "@/pages/customers/EditCustomerPage.vue"
      )
  }
];
