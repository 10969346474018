<template>
  <v-app>
    <!-- Layout component -->
    <component :is="currentLayout" v-if="logged">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </component>
    <component :is="currentLayout" v-else-if="notPermitted">
      <UnexpectedUser></UnexpectedUser>
    </component>
    <component :is="currentLayout" v-else>
      <loadingView></loadingView>
    </component>

    <v-snackbar
      v-model="toast.show"
      :timeout="toast.timeout"
      :color="toast.color"
      bottom
    >
      {{ toast.message }}
      <v-btn
        v-if="toast.timeout === 0"
        color="white"
        text
        @click="toast.show = false"
        >{{ $t("common.close") }}</v-btn
      >
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex";

// Demo Menu
import config from "./configs";

// Layouts
import defaultLayout from "./layouts/DefaultLayout";
import landingLayout from "./layouts/LandingLayout";
import simpleLayout from "./layouts/SimpleLayout";
import authLayout from "./layouts/AuthLayout";
import errorLayout from "./layouts/ErrorLayout";
import loadingView from "./pages/utility/LoadingPage.vue";
import UnexpectedUser from "./pages/error/UnexpectedUser.vue";

/*
|---------------------------------------------------------------------
| Main Application Component
|---------------------------------------------------------------------
|
| In charge of choosing the layout according to the router metadata
|
*/
export default {
  components: {
    defaultLayout,
    landingLayout,
    simpleLayout,
    authLayout,
    errorLayout,
    loadingView,
    UnexpectedUser
  },
  computed: {
    ...mapState("app", ["toast", "logged", "notPermitted"]),
    isLoaded: function() {
      if (this.$route.name !== null) return this.user !== undefined;

      return false;
    },
    currentLayout: function() {
      if (!this.logged) {
        return "errorLayout";
      }

      const layout = this.$route.meta.layout || "default";

      return layout + "Layout";
    }
  },
  created: function() {
    this.getUser();
  },
  methods: {
    ...mapActions("app", ["getUser"])
  },
  head: {
    link: [
      // adds config/icons into the html head tag
      ...config.icons.map(href => ({ rel: "stylesheet", href }))
    ]
  }
};
</script>

<style scoped>
/**
 * Transition animation between pages
 */
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
