import cards from './content/cards'

/*
|---------------------------------------------------------------------
| Board Vuex State
|---------------------------------------------------------------------
|
| Kanban board initial cards state from content demo
|
*/
export default {
  cards
}
