import { render, staticRenderFns } from "./App.vue?vue&type=template&id=6c42be79&scoped=true"
import script from "./App.vue?vue&type=script&lang=js"
export * from "./App.vue?vue&type=script&lang=js"
import style0 from "./App.vue?vue&type=style&index=0&id=6c42be79&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/.aspect_rules_js/vue-loader@15.11.1_-384693106/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c42be79",
  null
  
)

export default component.exports