import Vue from 'vue'
import moment from 'moment-timezone'
import store from '../store'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'

Vue.filter('formatDateDistance', (value) => {
  const { zone } = store.state.app.time

  if (value) {
    return formatDistanceToNow(moment(value).tz(zone).toDate()) + " ago"
  }

  return ''
})
