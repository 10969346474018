export default [{
  id: 1,
  title: 'find the report on one winged airplanes',
  description: '✈️ one wing is the way to go',
  labels: ['work'],
  completed: false
}, {
  id: 2,
  title: '🤘 get marshmallows for camping',
  description: 'we need it for reasons 🤤',
  labels: ['groceries'],
  completed: false
}, {
  id: 3,
  title: '🏄‍♀️ book surf lessons for September',
  description: '',
  labels: ['fun'],
  completed: false
}, {
  id: 4,
  title: 'Take my car to the shop',
  description: 'the brakes are broken',
  labels: [],
  completed: true
}, {
  id: 5,
  title: 'Choose a pool 🏊‍♂️ from the catalog',
  description: 'must fit the whole family',
  labels: ['fun', 'groceries'],
  completed: false
}]
