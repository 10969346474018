export default [
  {
    path: "/users",
    name: "users-list",
    component: () =>
      import(/* webpackChunkName: "users-list" */ "@/pages/users/UsersPage.vue")
  },
  {
    path: "/users/:uuid",
    name: "users-edit",
    component: () =>
      import(
        /* webpackChunkName: "users-edit" */ "@/pages/users/EditUserPage.vue"
      )
  }
];
