/*
|---------------------------------------------------------------------
| Email Vuex State
|---------------------------------------------------------------------
*/
export default {
  inbox: [],
  starred: [],

  // labels
  labels: [{
    id: 'work',
    title: 'Work',
    color: 'primary'
  }, {
    id: 'invoice',
    title: 'Invoice',
    color: 'green'
  }]
}
