export default [{ icon: 'mdi-cube-outline', text: 'Components', regex: /^\/ui\/components/,
  items: [
    { text: 'Alerts', link: '/ui/components/alerts' },
    { text: 'Badges', link: '/ui/components/badges' },
    { text: 'Bottom Navigation', link: '/ui/components/bottom-navigation' },
    { text: 'Bottom Sheets', link: '/ui/components/bottom-sheets' },
    { text: 'Buttons', link: '/ui/components/buttons' },
    { text: 'Calendars', link: '/ui/components/calendars' },
    { text: 'Cards', link: '/ui/components/cards' },
    { text: 'Carousels', link: '/ui/components/carousels' },
    { text: 'Date Pickers', link: '/ui/components/date-pickers' },
    { text: 'Dialogs', link: '/ui/components/dialogs' },
    { text: 'Expansion Panels', link: '/ui/components/expansion-panels' },
    { text: 'Floating Action Buttons', link: '/ui/components/floating-action-buttons' },
    { text: 'List Item Groups', link: '/ui/components/list-item-groups' },
    { text: 'Lists', link: '/ui/components/lists' },
    { text: 'Menus', link: '/ui/components/menus' },
    { text: 'Navigation Drawers', link: '/ui/components/navigation-drawers' },
    { text: 'Paginations', link: '/ui/components/paginations' },
    { text: 'Ratings', link: '/ui/components/ratings' },
    { text: 'Snackbars', link: '/ui/components/snackbars' },
    { text: 'Steppers', link: '/ui/components/steppers' },
    { text: 'System Bars', link: '/ui/components/system-bars' },
    { text: 'Tabs', link: '/ui/components/tabs' },
    { text: 'Timelines', link: '/ui/components/timelines' },
    { text: 'Tooltips', link: '/ui/components/tooltips' }
  ] },
{ icon: 'mdi-table-heart', text: 'Tables', regex: /^\/ui\/tables/,
  items: [
    { text: 'Data Tables', link: '/ui/tables/data-tables' },
    { text: 'Simple Tables', link: '/ui/tables/simple-tables' }
  ] },
{ icon: 'mdi-form-textbox', text: 'Forms', regex: /^\/ui\/forms/,
  items: [
    { text: 'Autocompletes', link: '/ui/forms/autocompletes' },
    { text: 'Combobox', link: '/ui/forms/combobox' },
    { text: 'File Inputs', link: '/ui/forms/file-inputs' },
    { text: 'Forms', link: '/ui/forms/forms' },
    { text: 'Inputs', link: '/ui/forms/inputs' },
    { text: 'Overflow Buttons', link: '/ui/forms/overflow-buttons' },
    { text: 'Selection Controls', link: '/ui/forms/selection-controls' },
    { text: 'Selects', link: '/ui/forms/selects' },
    { text: 'Sliders', link: '/ui/forms/sliders' },
    { text: 'Text Fields', link: '/ui/forms/text-fields' },
    { text: 'Textarea', link: '/ui/forms/textarea' }
  ] },
{ icon: 'mdi-dice-multiple-outline', text: 'Icons', regex: /^\/ui\/icons/,
  items: [
    { text: 'Boxicons', link: '/ui/icons/boxicons' },
    { text: 'Font Awesome', link: '/ui/icons/font-awesome' },
    { text: 'Material Design', link: '/ui/icons/material-design' },
    { text: 'Material Design Icons', link: '/ui/icons/material-design-icons' },
    { text: 'Usage', link: '/ui/icons/usage' }
  ] },
{ icon: 'mdi-chart-bar-stacked', text: 'Charts', regex: /^\/ui\/charts/,
  items: [
    { text: 'Apex Charts', link: '/ui/charts/apex-charts' },
    { text: 'Echarts', link: '/ui/charts/echarts' },
    { text: 'Sparklines', link: '/ui/charts/sparklines' }
  ] },
{ icon: 'mdi-map', text: 'Maps', regex: /^\/ui\/maps/,
  items: [
    { text: 'Google Maps', link: '/ui/maps/google-maps' }
  ] },
{ icon: 'mdi-one-up', text: 'Filters', regex: /^\/ui\/filters/,
  items: [
    { text: 'Capitalize', link: '/ui/filters/capitalize' },
    { text: 'Format Currency', link: '/ui/filters/format-currency' },
    { text: 'Format Date', link: '/ui/filters/format-date' },
    { text: 'Lowercase', link: '/ui/filters/lowercase' },
    { text: 'Uppercase', link: '/ui/filters/uppercase' }
  ] },
{ icon: 'mdi-palette-outline', text: 'Styles', regex: /^\/ui\/styles/,
  items: [
    { text: 'Cursor', link: '/ui/styles/cursor' },
    { text: 'Position', link: '/ui/styles/position' },
    { text: 'Sizing', link: '/ui/styles/sizing' }
  ] },
{ icon: 'mdi-format-color-fill', text: 'Colors', link: '/ui/colors' },
{ icon: 'mdi-view-dashboard-variant-outline', text: 'Grids', link: '/ui/grids' }]