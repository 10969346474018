// Will be replaced by a library
import Controller from "../controller";

const controller = new Controller(undefined, "whale");

const showToast = ({ state, commit }, message) => {
  if (state.toast.show) commit("hideToast");

  setTimeout(() => {
    commit("showToast", {
      color: "black",
      message,
      timeout: 3000,
    });
  });
};

const showError = ({ state, commit }, { message = "Failed!", error }) => {
  if (state.toast.show) commit("hideToast");

  setTimeout(() => {
    commit("showToast", {
      color: "error",
      message: message + " " + error.message,
      timeout: 10000,
    });
  });
};

const showSuccess = ({ state, commit }, message) => {
  if (state.toast.show) commit("hideToast");

  setTimeout(() => {
    commit("showToast", {
      color: "success",
      message,
      timeout: 3000,
    });
  });
};

const getUser = async ({ state, commit }) => {
  const user = await controller.me();

  if (!user) {
    window.location =
      controller.getUrl("/auth/redirect") +
      `?url=${window.location.toString()}`;
  }

  commit("setUser", user);
  commit("setDashboardData", await controller.getDashboardData());
};

const logout = async ({ state, commit }) => {
  await controller.logout();
  window.location =
    controller.getUrl("/auth/redirect") + `?url=${window.location.toString()}`;
  commit("setUser", undefined);
};

const createCustomer = async ({ state, commit }, { name }) => {
  const info = await controller.createCustomer(name);

  if (info) {
    commit("addCustomer", info);
    window.location = `/customers/${info.uuid}`;
  }
};

const updateUserCustomer = async ({ state, commit }, { customer, user }) => {
  await controller.updateUserCustomer(user, customer);
  commit("updateUserCustomer", { customer, user });
  commit("showToast", {
    color: "success",
    message: "User company assigned",
    timeout: 3000,
  });
};

const updateCustomer = async ({ state, commit }, { uuid, customer }) => {
  await controller.updateCustomer(uuid, customer);
  commit("updateCustomer", { uuid, customer });
  commit("showToast", {
    color: "success",
    message: "Customer updated",
    timeout: 3000,
  });
};

const getApiKeys = async () => {
  return await controller.getApiKeys();
};

const createApiKey = async (_context, api_key) => {
  return await controller.createApiKey(api_key);
};

const deleteApiKey = async (_context, api_key) => {
  return await controller.deleteApiKey(api_key);
};

export default {
  logout,
  getUser,
  showToast,
  showError,
  showSuccess,
  createCustomer,
  updateUserCustomer,
  updateCustomer,
  getApiKeys,
  createApiKey,
  deleteApiKey,
};
